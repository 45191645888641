import React, { useEffect } from 'react';
import s from './GoogleTranslate.module.less';

declare global {
  interface Window {
    googleTranslateElementInit?: () => void;
    google: any;
  }
}

const GoogleTranslate = () => {
  useEffect(() => {
    // 스크립트 로드
    const script = document.createElement('script');
    script.src =
      'https://translate.google.com/translate_a/element.js?cb=googleTranslateElementInit';
    script.async = true;
    script.id = 'google-translate-script';

    // 스크립트가 로드되었을 때 실행할 초기화 함수 설정
    window.googleTranslateElementInit = () => {
      const translateElement = new window.google.translate.TranslateElement(
        {
          pageLanguage: 'en', // 기본 언어 설정
          includedLanguages: 'en,ko', // 지원할 언어 설정
        },
        'google_translate_element'
      );
    };

    document.body.appendChild(script);

    // 컴포넌트 언마운트 시 스크립트 제거
    return () => {
      document.body.removeChild(script);
    };
  }, []);

  return (
    <div className={s.LanguageToggle}>
      <div id="google_translate_element" />
    </div>
  );
};

export default GoogleTranslate;
