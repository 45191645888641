import React, { useEffect } from 'react';
import { Provider } from 'react-redux';
import { CookiesProvider, useCookies } from 'react-cookie';

import AppRouter from './AppRouter';
import { store } from './redux/store';
import MediaQueryProvider from './contexts/MediaQueryProvider';
import config from './config/app';
import GoogleTranslate from './components/GoogleTranslate/GoogleTranslate';

function App() {
  const [cookies, setCookies, removeCookies] = useCookies([config.tokenKey]);

  useEffect(() => {
    setTimeout(() => {
      removeCookies(config.tokenKey, { domain: config.domain });
      window.location.replace(`${config.mainHost}/login`);
    }, 1000 * 60 * 60 * 4);
  }, []);

  return (
    <Provider store={store}>
      <CookiesProvider>
        <MediaQueryProvider>
          <AppRouter />
          <GoogleTranslate />
        </MediaQueryProvider>
      </CookiesProvider>
    </Provider>
  );
}

export default App;
